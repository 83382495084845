import React, { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import LoaderImage from "../../src/components/LoaderImage";
import { sendPhotoData, getClientData } from "../core/_requests";
import { toast } from "react-toastify";
import axios from "axios";
import EXIF from "exif-js";
import ExifReader from "exifreader";
import { RWebShare } from "react-web-share";

const VideoCapture = ({
  clientData,
  setClientData,
  setCamera,
  generalSettings,
}) => {
  const webcamRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [facingMode, setFacingMode] = useState("environment");
  const [dataUri, setDataUri] = useState();
  const [bottomText, setBottomText] = useState("");
  const [sendButton, setSendButton] = useState("Send");
  const [popup, setPopup] = useState(false);
  const [popupWarning, setPopupWarning] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  // const [camera, setCamera] = useState(false);
  const [orientation, setOrientation] = useState({
    width: window.innerWidth > window.innerHeight ? 1080 : 1920,
    height: window.innerWidth < window.innerHeight ? 1920 : 1080,
  });
  const [orientationValue, setOrientationValue] = useState(
    window.innerWidth > window.innerHeight ? "landscape" : "portrait"
  );
  const [metadata, setMetadata] = useState(null);
  const [error, setError] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [finalDate, setFinalDate] = useState("");
  const [captureType, setCaptureType] = useState("1");

  useEffect(() => {
    const [datePart, timePart] = new Date().toLocaleString().split(", ");

    // Split and rearrange the date part (DD/MM/YYYY to YYYY-MM-DD)
    const [day, month, year] = datePart.split("/");
    const formattedDate = `${year}-${month}-${day}`;

    // The time part is already in the correct format (HH:MM:SS)
    const formattedTime = timePart;

    setFinalDate(`${formattedDate} ${formattedTime}`);
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationValue(
        window.innerWidth > window.innerHeight ? "landscape" : "portrait"
      );
      setOrientation((prevOrientation) => ({
        width: window.innerWidth > window.innerHeight ? 1080 : 1920,
        height: window.innerWidth > window.innerHeight ? 1920 : 1080,
      }));
    };

    const mediaQuery = window.matchMedia("(orientation: portrait)");
    mediaQuery.addEventListener("change", handleOrientationChange);

    handleOrientationChange();

    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, [orientationValue]);

  const basePath = process.env.PUBLIC_URL;
  const APP_HOST = process.env.REACT_APP_HOST;

  const videoConstraints = {
    height: orientation.height,
    width: orientation.width,
    facingMode: facingMode,
  };

  const userMail = sessionStorage.getItem("data")
    ? JSON.parse(sessionStorage.getItem("data")).email
    : "Anonymous";
  const userName = sessionStorage.getItem("data")
    ? JSON.parse(sessionStorage.getItem("data")).given_name
    : "";
  const accpet_terms = sessionStorage.getItem("accpet_terms");

  const timeStamp = new Date().getTime();
  const pathArray = window.location.pathname.split("/");
  const id = pathArray[2];

  const newSliderData = () => {
    getClientData(id).then((response) => {
      setClientData(response.data);
    });
  };

  const sendPhoto = () => {
    let data = {
      client_id: Number(clientData.clients_id),
      client_photo: dataUri,
      date: finalDate,
      text: bottomText,
      username: userName,
      email: userMail,
      coordinates: "",
      bg_logo: 0,
    };

    setIsSaveLoading(true);
    if (typeof dataUri === "object") {
      dataUri[0].size < 100000000
        ? sendPhotoData(data)
            .then((response) => {
              if (response.status === 200) {
                if (response.photo_mode_status.moderation_status === "reject") {
                  setPopupWarning(true);
                  setTimeout(() => {
                    setPopupWarning(false);
                  }, 2000);
                }
                toast.success(response.error, {});
                //redirect to home page
              } else if (response.status === 201) {
                toast.error(response?.error, {});
              } else {
                toast.error("Something went wrong.", {});
              }
            })
            .finally(() => {
              setIsSaveLoading(false);
              newSliderData();
              // setSendButton("Sent");
              // setCamera(false);
              setDataUri();
            })
        : alert("File size too large");
    } else {
      sendPhotoData(data)
        .then((response) => {
          if (response.status === 200) {
            if (response.photo_mode_status.moderation_status === "reject") {
              setPopupWarning(true);
              setTimeout(() => {
                setPopupWarning(false);
              }, 2000);
            }
            toast.success(response.error, {});
            //redirect to home page
          } else if (response.status === 201) {
            toast.error(response?.error, {});
          } else {
            toast.error("Something went wrong.", {});
          }
        })
        .finally(() => {
          setIsSaveLoading(false);
          newSliderData();
          // setSendButton("Sent");
          // setCamera(false);
          setDataUri();
        });
    }
  };

  const handleStartCaptureClick = useCallback(() => {
    setDataUri();
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [recordedChunks]
  );

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setDataUri(imageSrc);
    setRecordedChunks([]);
    setTimeout(() => {
      let downloadButton = document.getElementById("download");
      downloadButton.click();
    }, 1000);
  };

  const handleDownload = () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `${clientData.app_name}-${timeStamp}.mp4`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleStopCaptureClick = () => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("No file selected");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const tags = ExifReader.load(e.target.result);
        if (Object.keys(tags).length === 0) {
          setError("No EXIF metadata found in image");
          setDateTime(null);
        } else {
          setMetadata(tags);
          setError(null); // Clear error

          // Extract DateTimeOriginal if available
          const dateTimeTag = tags.DateTimeOriginal;
          if (dateTimeTag) {
            const date = dateTimeTag.description.split(" ")[0];
            const time = dateTimeTag.description.split(" ")[1];
            const formattedDate = date.replaceAll(":", "-");
            setFinalDate(`${formattedDate} ${time}`);
          } else {
            setFinalDate("2001-01-01 08:54:45");
          }
        }
      } catch (error) {
        setError("Failed to read EXIF metadata");
        setDateTime(null);
      }
    };
    reader.onerror = () => {
      setError("Failed to read file");
      setDateTime(null);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (
      recordedChunks &&
      recordedChunks?.length > 0 &&
      mediaRecorderRef.current?.state === "inactive"
    ) {
      handleDownload();
    }
  }, [mediaRecorderRef.current, recordedChunks]);

  const sendVideoToBackend = useCallback(async () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const file = new File([blob], "recorded_video.mp4");

      const formData = new FormData();
      formData.append("client_id", Number(clientData.clients_id));
      formData.append("client_photo[]", file);
      formData.append("date", new Date().toLocaleString());
      formData.append("text", bottomText);
      formData.append("email", userMail);
      formData.append("coordinates", "");
      formData.append("bg_logo", 0);

      try {
        const response = await axios.post(
          "https://zaags.com/wp-json/clients/photocapture",
          formData
        );

        if (!response.ok) {
          throw new Error("Failed to upload video");
        }

        console.log("Video uploaded successfully");
      } catch (error) {
        console.error("Error uploading video:", error);
      }
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  return (
    <div>
      {popup === true && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-[100vw] h-[100vh] bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-[90%] z-100 max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="p-6  text-center mx-auto my-2 box-wrap-inner">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  By clicking the button below, you confirm complying with &
                  agreeing to the use of this media as per the&nbsp;
                  <a
                    href="https://zaags.com/gtc/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500 underline"
                  >
                    Terms & conditions
                  </a>
                </h3>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  onClick={() => {
                    setPopup(false);
                    sessionStorage.setItem("accpet_terms", "true");
                    sendPhoto();
                  }}
                >
                  Agree
                </button>
                <button
                  type="button"
                  className="text-white bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center
                  mr-2"
                  onClick={() => {
                    setPopup(false);
                  }}
                >
                  <a>No</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {popupWarning === true && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-[100vw] h-[100vh] bg-black opacity-40"></div>
          <div className="flex flex-row justify-center items-center min-h-screen  px-4 py-8">
            <img
              src={generalSettings.moderation_warning}
              alt="warning"
              className="max-w-[70%]"
            />
          </div>
        </div>
      )}

      {dataUri && (
        <div className="fixed inset-0 z-[5] overflow-y-auto">
          <div className="fixed inset-0 w-[100vw] h-[100vh] bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-[50%] max-w-lg p-4 mx-auto bg-gray-700 bg-opacity-50 rounded-md shadow-lg">
              <div className="flex flex-col mx-auto items-center">
                <img
                  src={
                    typeof dataUri === "object"
                      ? URL.createObjectURL(dataUri[0])
                      : dataUri
                  }
                  alt="captured_image"
                  className="mb-2"
                />
                <div className="flex flex-row justify-between">
                  <button
                    className="text-white bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    onClick={() => {
                      accpet_terms ? sendPhoto() : setPopup(true);
                    }}
                  >
                    {isSaveLoading ? <LoaderImage /> : sendButton}
                  </button>
                  {!isSaveLoading && (
                    <button
                      type="button"
                      className="text-white bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center
                  mr-2"
                      onClick={() => {
                        setDataUri();
                      }}
                    >
                      <a>Retry</a>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {recordedChunks.length > 0 && (
        <div className="fixed inset-0 z-[5] overflow-y-auto">
          <div className="fixed inset-0 w-[100vw] h-[100vh] bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-[90%] max-w-lg p-4 mx-auto bg-gray-700 bg-opacity-50 rounded-md shadow-lg">
              <div className="flex flex-col mx-auto items-center">
                <div className="flex flex-row justify-between">
                  <button
                    className="text-white bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    onClick={() => {
                      accpet_terms ? sendVideoToBackend() : setPopup(true);
                    }}
                  >
                    {isSaveLoading ? <LoaderImage /> : sendButton}
                  </button>
                  {!isSaveLoading && (
                    <button
                      type="button"
                      className="text-white bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center
                  mr-2"
                      onClick={() => {
                        setRecordedChunks([]);
                      }}
                    >
                      <a>Retry</a>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {orientationValue === "portrait" ? (
        <div className="flex flex-col justify-start relative w-full h-full">
          {clientData.is_bnb === "1" && (
            <div className="flex mx-auto flex-row items-center">
              <a
                target="_blank"
                className="mx-2 social-icon-color"
                href={`${APP_HOST}/app/${clientData.app_id}/gallery`}
              >
                Live Gallery
              </a>
              <RWebShare
                data={{
                  text: "Share",
                  url: `${APP_HOST}/app/${clientData.app_id}/gallery`,
                }}
              >
                <button>
                  {generalSettings.icon_share_app && (
                    <>
                      <img
                        className="cursor-pointer h-8 w-8 social-icon-color"
                        src="https://img.icons8.com/fluency-systems-regular/48/share.png"
                        alt="share"
                      />
                    </>
                  )}
                </button>
              </RWebShare>
            </div>
          )}
          <Webcam
            // audio={true}
            ref={webcamRef}
            videoConstraints={videoConstraints}
            className="!min-h-[80vh]"
          />
          <div className="w-full flex flex-row bg-gray-700 bg-opacity-50">
            <div className="flex flex-col mx-auto w-full">
              <div className="flex flex-row justify-center items-center">
                <p
                  className="text-white mx-2"
                  onClick={() => setCaptureType("1")}
                >
                  Photo
                </p>
                <p
                  className="text-white mx-2"
                  onClick={() => setCaptureType("2")}
                >
                  Video
                </p>
              </div>
              <div className="flex flex-row justify-around items-center">
                {!dataUri && recordedChunks.length <= 0 && (
                  <label
                    htmlFor="logo"
                    className="cursor-pointer flex flex-col items-center mt-2"
                  >
                    <img
                      src="http://zaags.com/wp-content/uploads/2024/06/upload_animated.gif"
                      height={30}
                      width={30}
                      className="mb-2"
                    />
                    <input
                      id="logo"
                      className="hidden logo "
                      accept="image/*, video/*"
                      type="file"
                      name="upload"
                      multiple
                      onChange={(e) => {
                        setDataUri(e.target.files);
                        setSendButton("Send");
                        handleImageUpload(e);
                      }}
                    />
                    Upload
                  </label>
                )}
                {captureType == "1" ? (
                  <button
                    onClick={capture}
                    className="flex flex-col items-center mt-2 "
                  >
                    <img
                      className="mb-2"
                      src="http://zaags.com/wp-content/uploads/2024/06/shutter_animated.gif"
                      height={30}
                      width={30}
                    />
                    Capture
                  </button>
                ) : capturing ? (
                  <button
                    className="flex flex-col items-center mt-2"
                    onClick={() => {
                      handleStopCaptureClick();
                    }}
                  >
                    <img
                      className="mb-2"
                      src="http://zaags.com/wp-content/uploads/2024/06/shoot_video.gif"
                      height={30}
                      width={30}
                    />
                    Stop video
                  </button>
                ) : (
                  <>
                    <button
                      onClick={handleStartCaptureClick}
                      className="flex flex-col items-center mt-2"
                    >
                      <img
                        className="mb-2"
                        src="http://zaags.com/wp-content/uploads/2024/06/shoot_video.gif"
                        height={30}
                        width={30}
                      />
                      Start video
                    </button>
                    {/* <p onClick={() => handleDownload()}>Download</p> */}
                  </>
                )}
                <button
                  className="flex flex-col items-center mt-2"
                  onClick={() =>
                    setFacingMode((prevFacingMode) =>
                      prevFacingMode === "user" ? "environment" : "user"
                    )
                  }
                >
                  <img
                    height={30}
                    width={30}
                    className="mb-2"
                    src="https://img.icons8.com/ios-filled/50/synchronize.png"
                    alt="synchronize"
                  />
                  Switch
                </button>
                <button
                  className="flex flex-col items-center mt-2"
                  onClick={() => window.open(`/app/${id}`, "_self")}
                >
                  {generalSettings.back_button_image && (
                    <img
                      className="mb-2"
                      src={generalSettings.back_button_image}
                      width="30"
                      height="30"
                      alt="back"
                    />
                  )}
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col ">
          {clientData.is_bnb === "1" && (
            <div className="flex mx-auto flex-row items-center">
              <a
                target="_blank"
                className="mx-2 social-icon-color"
                href={`${APP_HOST}/app/${clientData.app_id}/gallery`}
              >
                Live Gallery
              </a>
              <RWebShare
                data={{
                  text: "Share",
                  url: `${APP_HOST}/app/${clientData.app_id}/gallery`,
                }}
              >
                <button>
                  {generalSettings.icon_share_app && (
                    <>
                      <img
                        className="cursor-pointer h-8 w-8 social-icon-color"
                        src="https://img.icons8.com/fluency-systems-regular/48/share.png"
                        alt="share"
                      />
                    </>
                  )}
                </button>
              </RWebShare>
            </div>
          )}
          <div className="flex flex-row justify-center relative w-full h-full mt-6">
            <button
              className="flex flex-col items-center mr-2"
              onClick={() => window.open(`/app/${id}`, "_self")}
            >
              {generalSettings.back_button_image && (
                <img
                  src={generalSettings.back_button_image}
                  height={30}
                  width={30}
                  alt="back"
                  className="mb-2"
                />
              )}
            </button>
            <Webcam
              // audio={true}
              ref={webcamRef}
              videoConstraints={videoConstraints}
              className="!max-h-[90vh]"
            />
            <div className="flex flex-col bg-gray-700 bg-opacity-50 !h-[90vh]">
              <div className="flex flex-col justify-around items-center p-4 h-full">
                <div className="flex flex-row justify-center items-center">
                  <p
                    className="text-white mx-2"
                    onClick={() => setCaptureType("1")}
                  >
                    Photo
                  </p>
                  <p
                    className="text-white mx-2"
                    onClick={() => setCaptureType("2")}
                  >
                    Video
                  </p>
                </div>
                {!dataUri && recordedChunks.length <= 0 && (
                  <label
                    htmlFor="logo"
                    className="cursor-pointer flex flex-col items-center mt-2"
                  >
                    <img
                      src="http://zaags.com/wp-content/uploads/2024/06/upload_animated.gif"
                      height={30}
                      width={30}
                      className="mb-2"
                    />
                    <input
                      id="logo"
                      className="hidden logo "
                      accept="image/*, video/*"
                      type="file"
                      name="upload"
                      multiple
                      onChange={(e) => {
                        setDataUri(e.target.files);
                        setSendButton("Send");
                        handleImageUpload(e);
                      }}
                    />
                    Upload
                  </label>
                )}
                {captureType == "1" ? (
                  <button
                    onClick={capture}
                    className="flex flex-col items-center mt-2 "
                  >
                    <img
                      className="mb-2"
                      src="http://zaags.com/wp-content/uploads/2024/06/shutter_animated.gif"
                      height={30}
                      width={30}
                    />
                    Capture
                  </button>
                ) : capturing ? (
                  <button
                    className="flex flex-col items-center mt-2"
                    onClick={() => {
                      handleStopCaptureClick();
                    }}
                  >
                    <img
                      className="mb-2"
                      src="http://zaags.com/wp-content/uploads/2024/06/shoot_video.gif"
                      height={30}
                      width={30}
                    />
                    Stop video
                  </button>
                ) : (
                  <>
                    <button
                      onClick={handleStartCaptureClick}
                      className="flex flex-col items-center mt-2"
                    >
                      <img
                        className="mb-2"
                        src="http://zaags.com/wp-content/uploads/2024/06/shoot_video.gif"
                        height={30}
                        width={30}
                      />
                      Start video
                    </button>
                    {/* <p onClick={() => handleDownload()}>Download</p> */}
                  </>
                )}
                <button
                  className="flex flex-col items-center mt-2"
                  onClick={() =>
                    setFacingMode((prevFacingMode) =>
                      prevFacingMode === "user" ? "environment" : "user"
                    )
                  }
                >
                  <img
                    height={30}
                    width={30}
                    className="mb-2"
                    src="https://img.icons8.com/ios-filled/50/synchronize.png"
                    alt="synchronize"
                  />
                  Switch
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {dataUri && (
        <div className="flex flex-row items-center justify-evenly">
          {/* <img width={150} src={dataUri} alt="Captured" /> */}
          <a
            id="download"
            className="bg-blue-500 hover:bg-blue-700 text-white w-[100px] font-bold my-2 mx-2 py-2 px-3 rounded hidden"
            download={`${clientData.app_name}-${timeStamp}`}
            href={dataUri}
          >
            Download
          </a>
        </div>
      )}

      <div className="flex flex-row justify-between">
        {/* <button
            className="flex flex-col items-center mt-2"
            onClick={() => {
              setOrientation((prevOrientation) => ({
                width: prevOrientation.width === 1080 ? 1920 : 1080,
                height: prevOrientation.height === 1920 ? 1080 : 1920,
              }));
            }}
          >
            <img
              width="30"
              height="30"
              src={generalSettings.icon_orientation}
              alt="synchronize"
            />
            Change Orientation
          </button> */}
      </div>
    </div>
  );
};

export default VideoCapture;
